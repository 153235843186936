<template id="broghue">
  <product title="Broghue"
  >
    <template v-slot:image>
      <slot>
        <img src="@/assets/broghue.jpg">


      </slot>
    </template>
  </product>
</template>
<script>


import product from "@/components/partials/PageTransitions/PageTransitionsNr1/components/product";

export default {
  name: "broghue",


  data() {


    return {



    }

  },

  components: {
    product
  }
}
</script>

<style lang="scss"></style>
